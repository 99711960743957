/* eslint-disable max-lines */
import
{
  APP_INITIALIZER, NgModule, isDevMode
} from "@angular/core";

import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";

import { AppRoutingModule } from "./app-routing.module";

import
{ provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { StoreModule } from "@ngrx/store";
import { StoreRouterConnectingModule } from "@ngrx/router-store";

import
{
  MsalRedirectComponent
} from "@azure/msal-angular";

import { AuthConfigModule } from "./auth/auth-config.module";

import { AppComponent } from "./app.component";

import { CONSTANTS } from "./shared/constants";

import { ConfigService } from "./shared/services/config.service";

import { AppEffects } from "./store/app.effects";
import { appReducer } from "./store/app.reducers";

import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MessageComponent } from "./message/message.component";
import { ResponseErrorHandler } from "./shared/services/response-error-handler.service";

import { FooterModule } from "./footer/footer.module";
import { HeaderModule } from "./header/header.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";


const appInitializerFn = (appConfig: ConfigService) =>
{
  return () =>
  {
    return appConfig.init(true);
  };
};

@NgModule({ declarations: [
        AppComponent,
        MessageComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AuthConfigModule,
        CommonModule,
        MatIconModule,
        MatSnackBarModule,
        HeaderModule,
        FooterModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !isDevMode(),
            /*
             * Register the ServiceWorker as soon as the application is stable
             * or after 30 seconds (whichever comes first).
             */
            registrationStrategy: "registerWhenStable:30000"
        }),
        StoreModule.forRoot({ "Data": appReducer }, {}),
        EffectsModule.forRoot([AppEffects]),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: CONSTANTS.StoreDevtoolsConfig.maxAge, logOnly: !isDevMode(),
            connectInZone: true
        })], providers: [
        ResponseErrorHandler,
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [ConfigService]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule
{ }