/* eslint-disable max-lines */
import
{
  APP_INITIALIZER, NgModule
} from "@angular/core";

import
  {
    AppSettings, ConfigService
  } from "../shared/services/config.service";

import
{
  BrowserCacheLocation,
  Configuration,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import
{
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  ProtectedResourceScopes
} from "@azure/msal-angular";
import
{
  firstValueFrom,
  map,
  tap
} from "rxjs";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { APIManagementHttpInterceptor } from "./api-management.interceptor";
import { ClaimsRequest } from "./claim-utils";
import { getClaimsFromStorage } from "./storage-utils";

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string)
{
 // console.log(message);
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
// eslint-disable-next-line max-lines-per-function
export function MSALInstanceFactory(config: ConfigService)
  : IPublicClientApplication
{
  const msalConfig = ConfigService
    .getSettings<AppSettings, "msal">(config.CurrentConfig, "msal");

  /**
   * Configuration object to be passed to MSAL instance on creation.
   * For a full list of MSAL.js configuration parameters, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
   */
  const msalConfiguration: Configuration =
  {
    auth :
    {
      // This is the ONLY mandatory field that you need to supply.
      clientId : msalConfig.auth.clientId,

      // Defaults to "https://login.microsoftonline.com/common"
      authority : msalConfig.auth.authority,

      /*
       * Points to window.location.origin by default.
       * You must register this URI on Azure portal/ App Registration.
       */
      redirectUri : msalConfig.auth.redirectUri,

      // Points to window.location.origin by default.
      postLogoutRedirectUri : msalConfig.auth.postLogoutRedirectUri,

      /*
       * This lets the resource server know
       * that this client can handle claim challenges.
       */
      clientCapabilities : ["CP1"]
    },
    cache : {
      /*
       * Configures cache location. "sessionStorage" is more secure,
       *      but "localStorage" gives you SSO between tabs.
       */
      cacheLocation : BrowserCacheLocation.LocalStorage,

      /*
       * Set this to "true" if you are having issues on IE11 or Edge.
       *      Remove this line to use Angular Universal
       */
      storeAuthStateInCookie : msalConfig.cache?.storeAuthStateInCookie
        ?? isIE
    },
    system : {
      // Disables WAM Broker
      allowNativeBroker : false,

      /**
       * Below you can configure MSAL.js logs. For more information, visit:
       * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
       */
      loggerOptions : {
        loggerCallback,
        logLevel : msalConfig.system?.loggerOptions?.logLevel
          ?? LogLevel.Info,
        piiLoggingEnabled : msalConfig.system?.loggerOptions?.piiLoggingEnabled
          ?? false
      }
    }
  };

  return new PublicClientApplication(msalConfiguration);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
// eslint-disable-next-line max-lines-per-function
export function MSALInterceptorConfigFactory(config: ConfigService)
  : MsalInterceptorConfiguration
{
  const msalConfig = ConfigService
    .getSettings(config.CurrentConfig, "msal");
  const interceptorConfig =
    ConfigService.getSettings(config.CurrentConfig, "interceptor");

  /**
   * Add here the endpoints and scopes when obtaining an
   * access token for protected web APIs. For more information, see:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
   */
  const protectedResourceMap =
    new Map<string, Array<string | ProtectedResourceScopes>
      | null>(interceptorConfig.protectedResourceMap);

  return {
    interactionType : InteractionType.Redirect,
    protectedResourceMap,
    authRequest     : (msalService, httpReq, originalAuthRequest) =>
    {
      const resource = new URL(httpReq.url).hostname;
      const claims = msalService.instance.getActiveAccount()
        ? getClaimsFromStorage(
          `cc.${msalConfig.auth.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid
          }.${resource}`
        ) : null;
      // eslint-disable-next-line no-undefined
      const claim = claims ? window.atob(claims) : undefined;

      return {
        ...originalAuthRequest,
        claims : claim
      };
    }
  };
}

/**
 * Set your default interaction type for MSALGuard here.
 * If you have any additional scopes you want the user
 * to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(config: ConfigService)
  : MsalGuardConfiguration
{
  const guardConfig = ConfigService.getSettings(config.CurrentConfig, "guard");

  const claims: ClaimsRequest = {
    "id_token" : {
      "acct" : {
        "essential" : true
      },
      "in_corp" : {
        "essential" : true
      }
    }
  };

  return {
    interactionType : InteractionType.Redirect,

    /**
     * Scopes you add here will be prompted for user consent during sign-in.
     * By default, MSAL.js will add OIDC scopes (openid, profile, email)
     * to any login request. For more information about OIDC scopes, visit:
     * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
     * For dynamically change the authRequest at runtime:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/configuration.md#msalguard---dynamic-auth-request
     */
    authRequest : {
      ...guardConfig.authRequest, claims : JSON.stringify(claims)
    },
    loginFailedRoute : guardConfig.loginFailedRoute
  };
}
export function initializerFactory(config: ConfigService)
{
  const promise = firstValueFrom(
    config.Config.pipe(
      // eslint-disable-next-line no-undefined
      map(() => undefined),
      tap(() => console.log("finished getting configurations dynamically."))));

  return () => promise;
}

@NgModule({
  imports : [MsalModule],
  providers :
    [
      {
        provide    : APP_INITIALIZER,
        useFactory : initializerFactory,
        deps       : [ConfigService],
        multi      : true
      },
      {
        provide    : MSAL_INSTANCE,
        useFactory : MSALInstanceFactory,
        deps       : [ConfigService]
      },
      {
        provide    : MSAL_GUARD_CONFIG,
        useFactory : MSALGuardConfigFactory,
        deps       : [ConfigService]
      },
      {
        provide    : MSAL_INTERCEPTOR_CONFIG,
        useFactory : MSALInterceptorConfigFactory,
        deps       : [ConfigService]
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService,
      {
        provide  : HTTP_INTERCEPTORS,
        useClass : MsalInterceptor,
        multi    : true
      },
      {
        provide  : HTTP_INTERCEPTORS,
        useClass : APIManagementHttpInterceptor,
        multi    : true
      }
    ]
})
export class AuthConfigModule
{ }