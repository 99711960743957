/* eslint-disable max-lines-per-function */
import { Injectable } from "@angular/core";

import
{ HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

import {
 Observable, switchMap
} from "rxjs";

import { ConfigService } from "../shared/services/config.service";

@Injectable()
export class APIManagementHttpInterceptor implements HttpInterceptor
{
  readonly redirectedPaths = [
    "/api/Permission",
    "/api/Permission/GetOrganization",
    "/api/Intervention/GetCounters",
    "/api/Intervention/GetOptions",
    "/api/Intervention/GetConnectedOptions",
    "/api/Intervention/GetOptionsFiltered",
    "/api/Intervention/UpdateIntervention",
    "/api/Intervention/UpdateInterventionDocuments"
  ];

  constructor(protected readonly configService: ConfigService)
  {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler)
    : Observable<HttpEvent<unknown>>
  {
    // Get the path from the request URL
    const reqPath = new URL(req.url).pathname;

    // Find the path that matches the end of the request URL
    const matchingPath = this.redirectedPaths.find(p => reqPath.endsWith(p));

    if (matchingPath)
    {
      // Get the URL from the config service
      return this.configService.Config.pipe(
        switchMap((settings) =>
        {
          const interceptorConfig = settings.apiManagementInterceptor;

          if (interceptorConfig)
          {
            /*
             * Clone the request and replace the original URL with the new one,
             * and add a new header
             */
            const clonedRequest = req.clone({
              url        : `${interceptorConfig.url}${matchingPath}`,
              setHeaders : interceptorConfig.headers
            });

            // Forward the cloned request
            return next.handle(clonedRequest);
          }

          /*
           * If the request URL does not match any of the paths,
           * forward the original request
           */
          return next.handle(req);
        })
      );
    }

    /*
     * If the request URL does not match any of the paths,
     * forward the original request
     */
    return next.handle(req);
  }
}