import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";

import { Relative } from "../models/relative";

@Injectable({
  providedIn: "root"
})
export class RelativeService
{
  private static readonly url = "Relative";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("RelativeService");
  }

  getRelativesByPersonId(personId: string): Observable<Relative[]>
  {
    return this.api.get<Relative[]>(`${RelativeService.url}/${personId}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getRelativesByPersonId", new Array<Relative>()))
    );
  }
}