import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";

import { Id } from "../models/id";

@Injectable({
  providedIn: "root"
})
export class IdService
{
  private static readonly url = "ID";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("IdService");
  }

  getIDsByPersonId(personId: string): Observable<Id[]>
  {
    return this.api.get<Id[]>(`${IdService.url}/${personId}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getIDsByPersonId", new Array<Id>()))
    );
  }
}