<div class="wrap-message">
  <div [ngClass]="(message.Type | lowercase)"
       class="message">
    <div class="message-header">

      <mat-icon class="d-block"
                [ngClass]="(message.Type | lowercase)">
      </mat-icon>
      <div class="actions close">
        <!-- *ngIf="message.AutoClose || !message.ActionList?.length" -->
        <mat-icon (click)="messageRemove()">close</mat-icon>
      </div>
      <b>
        {{constants.Message[message.Type]}}
      </b>

    </div>
    <div class="message-body">
      <span>{{message.Message}}</span>
    </div>

    <div class="actions-content">
      <div class="actions"
           *ngFor="let action of message.ActionList">
        <a (click)="dispatchAction(action)"> {{action.btnLabel?action.btnLabel:constants.Message[action.type]}}</a>
      </div>
    </div>
    <!-- *ngIf="!message.AutoClose" -->
  </div>
</div>