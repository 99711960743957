/* eslint-disable max-lines */
import { NgModule } from "@angular/core";

import
{
  RouterModule, Routes
} from "@angular/router";
import { BrowserUtils } from "@azure/msal-browser";

import
{
  MsalGuard,
  MsalRedirectComponent
} from "@azure/msal-angular";

import { canMatch } from "./shared/services/auth-can-match.service";

const routes: Routes = [
  {
    path       : "", redirectTo : "/user-desktop", pathMatch  : "full",
    data       : { breadcrumb: "redirect" }
  },
  {
    // Needed for handling redirect after login
    path      : "auth",
    component : MsalRedirectComponent
  },
  {
    path         : "user-desktop",
    loadChildren : () =>
      import("./user-desktop/user-desktop.module").then(
        (m) => m.UserDesktopModule
      ),
    canMatch : [canMatch],
    data     : {
      breadcrumb : "desktop"
    }
  },
  {
    path: "waiting-user",
    loadChildren: () =>
      import("./waiting-user/waiting-user.module").then((m) => m.WaitingUserModule),
    canMatch: [canMatch],
    data: { breadcrumb: "waiting-user" }
  },
  {
    path         : "committee",
    loadChildren : () =>
      import("./committee/committee.module").then((m) => m.CommitteeModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "committee" }
  },
  {
    path         : "cya-committee",
    loadChildren : () =>
      import("./cya-committee/cya-committee.module").then((m) => m.CYACommitteeModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "cya-committee" }
  },
  {
    path         : "activity",
    loadChildren : () =>
      import("./activity/activity.module").then((m) => m.ActivityModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "activity" }
  },
  {
    path: "protocol",
    loadChildren: () =>
      import("./protocol/protocol.module").then((m) => m.ProtocolModule),
    canMatch: [canMatch]
  },
  {
    path         : "unsent-protocols",
    loadChildren : () =>
      import("./unsent-protocols/unsent-protocols.module").then((m) => m.UnsentProtocolsModule),
    canMatch : [canMatch]
  },
  {
    path         : "reference",
    loadChildren : () =>
      import("./reference/reference.module").then((m) => m.ReferenceModule),
    canMatch : [canMatch]
  },
  {
    path         : "student",
    loadChildren : () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "student" }
  },
  {
    path: "intensive-follow-up",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "intensiveAndFollowUp" }
  },
  {
    path: "browse-students",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "browse-students" }
  },
  {
    path: "patients",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "patients" }
  },
  {
    path: "intervention-program",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "intervention-program" }
  },
  {
    path: "intake",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "intake" }
  },
  {
    path: "history",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "history" }
  },
  {
    path: "end-and-parting",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
    canMatch: [canMatch],
    data: { breadcrumb: "end-and-parting" }
  },
  {
    path         : "project",
    loadChildren : () =>
      import("./project/project.module").then((m) => m.ProjectModule),
    canMatch : [canMatch]
  },
  {
    path         : "employee",
    loadChildren : () =>
      import("./employee/employee.module").then((m) => m.EmployeeModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "employee" }
  },
  {
    path: "reading-employees",
    loadChildren: () =>
      import("./employee/employee.module").then((m) => m.EmployeeModule),
    canMatch: [canMatch],
    data: { breadcrumb: "reading-employees" }
  },
  {
    path         : "calendar",
    loadChildren : () =>
      import("./calendar/calendar.module").then((m) => m.CalendarModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "calendar" }
  },
  {
    path: "activities",
    loadChildren: () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch: [canMatch],
    data: { breadcrumb: "activities" }
  },
  {
    path: "open-activities",
    loadChildren: () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch: [canMatch],
    data: { breadcrumb: "open-activities" }
  },
  {
    path         : "intervention",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "intervention" }
  },
  {
    path         : "open-interventions",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "open-interventions" }
  },
  {
    path         : "emergency-events",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "emergency-events" }
  },
  {
    path         : "reading-interventions",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "reading-interventions" }
  },
  {
    path: "reading-reports",
    loadChildren: () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch: [canMatch],
    data: { breadcrumb: "reading-reports" }
  },
  {
    path         : "open-interventions",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "open-interventions" }
  },
  {
    path         : "emergency-events",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "emergency-events" }
  },
  {
    path         : "student-refers",
    loadChildren : () =>
      import("./student-refer/student-refer.module").then((m) => m.StudentReferModule),
      canMatch : [canMatch],
    data     : { breadcrumb: "student-refers" }
  },
  {
    path         : "end-treatment",
    loadChildren : () =>
      import("./end-tratment/end-treatment.module").then((m) => m.EndTreatmentModule),
      canMatch : [canMatch],
    data     : { breadcrumb: "end-treatment" }
  },
  {
    path         : "reading-interventions",
    loadChildren : () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "reading-interventions" }
  },
  {
    path: "reading-reports",
    loadChildren: () =>
      import("./intervention/intervention.module").then((m) => m.InterventionModule),
    canMatch: [canMatch],
    data: { breadcrumb: "reading-reports" }
  },
  {
    path: "institution",
    loadChildren: () =>
      import("./institution/institution.module").then((m) => m.InstitutionModule),
    canMatch : [canMatch],
    data     : { breadcrumb: "institution" }
  },
  {
    path: "reading-education-frameworks",
    loadChildren: () =>
      import("./institution/institution.module").then((m) => m.InstitutionModule),
    canMatch: [canMatch],
    data: { breadcrumb: "reading-education-frameworks" }
  },
  {
    path         : "blocked",
    loadChildren : () => import("./blocked/blocked.module").then(m => m.BlockedModule)
  },
  {
    path         : "unregistered",
    loadChildren : () => import("./unregistered/unregistered.module").then(m => m.UnregisteredModule)
  },
  {
    path         : "waiting-user",
    loadChildren : () => import("./waiting-user/waiting-user.module").then(m => m.WaitingUserModule),
    // canMatch : [canMatch],
    // data     : { breadcrumb: "cya-committee" }
  }

  /*
   * { path: 'links', component: LinksComponent },
   * { path: 'home', component: HomeComponent },
   */
];

@NgModule({
  imports : [
    RouterModule.forRoot(routes,
    {

      // Don't perform initial navigation in iframes or popups
      initialNavigation :
        !BrowserUtils.isInIframe()
          && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"

           // Set to enabledBlocking to use Angular Universal
          : "disabled"
  })],
  exports : [RouterModule]
})
export class AppRoutingModule
{ }