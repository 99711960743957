import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";

import { CivilStatus } from "../models/civil-status";

@Injectable({
  providedIn: "root"
})
export class CivilStatusService
{
  private static readonly url = "CivilStatus";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("CivilStatusService");
  }

  getCivilStatusesByPersonId(personId: string): Observable<CivilStatus[]>
  {
    return this.api.get<CivilStatus[]>(`${CivilStatusService.url}/${personId}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getCivilStatusesByPersonId", new Array<CivilStatus>()))
    );
  }
}