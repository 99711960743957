/* eslint-disable max-params, max-lines, max-lines-per-function */
import
{
  ApplicationRef,
  Component, Inject, OnDestroy, OnInit
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import
{
  NoNewVersionDetectedEvent,
  SwUpdate,
  VersionDetectedEvent,
  VersionInstallationFailedEvent,
  VersionReadyEvent
} from "@angular/service-worker";

import
{
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus
} from "@azure/msal-browser";
import
{
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from "@azure/msal-angular";

import { StorageMap } from "@ngx-pwa/local-storage";
import { Network } from "@ngx-pwa/offline";

import
{
  Observable,
  ReplaySubject,
  Subject,
  concat,
  filter,
  first,
  interval,
  merge,
  takeUntil
} from "rxjs";
import
  {
    MatSnackBar, MatSnackBarConfig
  } from "@angular/material/snack-bar";

import { Store } from "@ngrx/store";

import { AuthService } from "./auth/auth.service";
import { createClaimsTable } from "./auth/claim-utils";

import { DocumentsProtocol } from "./shared/models/documents-protocol";
import { UserRole } from "./shared/models";

import { ProtocolService } from "./shared/services/protocol.service";

import { addedRoleUser } from "./store/app.actions";
import { selectAllMessages } from "./store/app.selectors";

import { Message } from "./message/message";
import { insertProtocol } from "./protocol/store/protocol.actions";
import { CONSTANTS } from "./shared/constants";

@Component({
  selector    : "tlv-root",
  templateUrl : "./app.component.html",
  styleUrls   : ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy
{
  title = "Client";

  messages$ = new Observable<(Message | undefined)[]>();

  userName!: string;

  isIframe = false;

  private readonly destroying$ = new Subject<void>();

  private readonly awaitingRestart = new ReplaySubject<void>(1);

  constructor(@Inject(DOCUMENT) private document: Document,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private storageMap: StorageMap,
    private protocolService: ProtocolService,
    private network: Network,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private readonly store: Store,
    private readonly snackBar: MatSnackBar
  )
  {
    this.messages$ = this.store.select(selectAllMessages);

    // Check if service worker supported in this browser.
    if (this.swUpdate.isEnabled)
    {
      console.log("PWA is enabled");

      const appIsStable$ = this.appRef.isStable
        .pipe(first(isStable => isStable === true));
      const pollingInterval = interval(CONSTANTS.UpdatePollingInterval);
      const intervalPollingOnceAppIsStable$ =
        concat(appIsStable$, pollingInterval);

      intervalPollingOnceAppIsStable$
        .pipe(
          takeUntil(this.destroying$)
        ).subscribe(() =>
        {
          (async () =>
          {
            try
            {
              const updateFound = await swUpdate.checkForUpdate();

              console.log(updateFound ? "A new version is available." : "Already on the latest version.");
            }
            catch (err)
            {
              console.error("Failed to check for updates:", err);
            }
          })().catch((e) => console.error(e));
        });
    }
    else
    {
      console.log("PWA is not enabled");
    }

    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is NoNewVersionDetectedEvent => evt.type === "NO_NEW_VERSION_DETECTED"),
        takeUntil(this.destroying$)
      )
      .subscribe(evt =>
        console.log(`Current app version: ${evt.version.hash} is the latest`));
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionDetectedEvent => evt.type === "VERSION_DETECTED"),
        takeUntil(this.destroying$)
      )
      .subscribe(evt =>
        console.log(`Downloading new app version: ${evt.version.hash}`));
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
        takeUntil(merge(this.awaitingRestart, this.destroying$))
      )
      .subscribe(evt =>
      {
        this.awaitingRestart.next();
        console.log(`Current app version: ${evt.currentVersion.hash}`);
        console.log(`New app version ready for use: ${evt.latestVersion.hash}`);

        const conf: MatSnackBarConfig =
        {
          horizontalPosition : "end",
          verticalPosition   : "top",
          direction          : "rtl"
        };

        this.snackBar.open("New app version ready for use, but a reload is necessary, please save your work before proceeding.", "Proceed", conf)
          .afterDismissed()
          .subscribe(() =>
          {
            document.location.reload();
          });
      });
    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionInstallationFailedEvent => evt.type === "VERSION_INSTALLATION_FAILED"),
        takeUntil(this.destroying$)
      )
      .subscribe(evt =>
        console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`));

    swUpdate.unrecoverable
      .pipe(
        takeUntil(this.destroying$)
      ).subscribe(event =>
      {
        this.awaitingRestart.next();
        console.error(
          "An error occurred that we cannot recover from:\n"
          + event.reason
          + "\n\nPlease reload the page."
        );

        const conf: MatSnackBarConfig =
        {
          horizontalPosition : "end",
          verticalPosition   : "top",
          direction          : "rtl"
        };

        this.snackBar.open("An error occurred that we cannot recover from, please reload the page", "Proceed", conf)
          .afterDismissed()
          .subscribe(() =>
          {
            document.location.reload();
          });
      });
  }


  ngOnInit(): void
  {
    // Remove this line to use Angular Universal
    this.isIframe = window !== window.parent && !window.opener;

    /*
     * Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events
     * emitted when a user logs in or out of another tab or window
     */
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.ACCOUNT_ADDED
          || msg.eventType === EventType.ACCOUNT_REMOVED),
        takeUntil(this.destroying$)
      )
      .subscribe(() =>
      {
        if (this.msalService.instance.getAllAccounts().length === 0)
        {
          window.location.pathname = "/";
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) =>
      {
        console.log("token=", (result.payload as AuthenticationResult).accessToken);
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) =>
      {
        console.log(result);
        const account = (result?.payload as AuthenticationResult).account;

        if (account)
        {
          this.msalService.instance.setActiveAccount(account);
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) =>
          status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() =>
      {
        this.userName = this.authService.userFullName;

        createClaimsTable(
          this.msalService.instance.getActiveAccount()?.idTokenClaims);
      });

    this.store.dispatch(addedRoleUser({ role: UserRole.Admin }));

    this.network.onlineChanges
      .pipe(
        takeUntil(this.destroying$)
      ).subscribe((isConnected) =>
      {
        this.protocolService.inConnectedMode = isConnected;
        console.log(`Connected mode: ${isConnected}`);

        // TODO to remove.
        this.storageMap.clear().subscribe();

        if (isConnected)
        {
          this.storageMap.get("unsentProtocolsList").subscribe((unsentProtocolsJSON: unknown) =>
          {
            if (unsentProtocolsJSON)
            {
              const unsentProtocolsList
                = JSON.parse(
                  unsentProtocolsJSON as string
                ) as DocumentsProtocol[];

              unsentProtocolsList.forEach(unsentProtocol =>
              {
                this.store.dispatch(insertProtocol(
                  {
                    protocol : unsentProtocol.InterventionInsertDTO, documents : unsentProtocol.documents
                  }));
              });
            }
          });
        }
      });
  }

  ngOnDestroy(): void
  {
    this.destroying$.next();
    this.destroying$.complete();
  }
}