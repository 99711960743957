/* eslint-disable max-lines-per-function */
import { Injectable } from "@angular/core";
import { DataRow } from "shared-lib";
import { CONSTANTS } from "../constants";
import { Fill, Workbook } from "exceljs";
import * as fs from "file-saver";
import { from } from "rxjs";

@Injectable({
  providedIn : "root"
})
export class ExcelService
{
  constructor()
  {}

  exportAsExcelFile(json: (DataRow | undefined)[], headers: string[],
    sheetName?: string, fileName = CONSTANTS.Excel.DefaultFileName)
  {
    // Title, Header & Data
    const workbook = new Workbook();

    const worksheet = workbook.addWorksheet(sheetName);

    const headerRow = worksheet.addRow(headers);

    worksheet.views = [{ rightToLeft: true }];

    // Style header row
    headerRow.eachCell((cell) =>
    {
      cell.fill = CONSTANTS.Excel.HeaderFill as Fill;
      cell.font = CONSTANTS.Excel.HeaderFont;
    });

    json.map(d => d ? worksheet.addRow(Object.values(d)) : {});

    worksheet.columns.forEach(col => col.width = CONSTANTS.Excel.ColumnWidth);

    // Generate & Save Excel File
    return from(workbook.xlsx.writeBuffer()
      .then((d) =>
      {
        const blob = new Blob([d], {
          type : CONSTANTS.Excel.BlobType
        });

        fs.saveAs(blob, fileName + CONSTANTS.Excel.Format);

        return true;
      }).catch((error) =>
      {
        throw error;
      }));
  }
}