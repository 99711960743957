/* eslint-disable camelcase */
/* eslint-disable max-lines, max-lines-per-function */

import { HttpResponse } from "@angular/common/http";
import { MsalService } from "@azure/msal-angular";

import { assertIsDefined } from "shared-lib";

import { addClaimsToStorage } from "./storage-utils";

// https://www.connect2id.com/products/server/docs/guides/requesting-openid-claims
export const TokenType =
{
  IdToken     : "id_token",
  AccessToken : "access_token",
  UserInfo    : "userinfo"
} as const;
export type AnyTokenType = (typeof TokenType)[keyof typeof TokenType];

export type TokenRequirement =
  Record<"essential", true>
| Record<"values", (string | number)[]>
| null;

export type Token =
{
  [K: string]: TokenRequirement;
};

export type ClaimsRequest =
{
  [K in AnyTokenType]?: Token;
};

/**
 * This method parses WWW-Authenticate authentication headers
 * @param header
 * @return {Object} challengeMap
 */
export const parseChallenges = (header: string): Record<string, string> =>
{
  const schemeSeparator = header.indexOf(" ");
  const challenges = header.substring(schemeSeparator + 1).split(", ");
  const challengeMap = {} as Record<string, string>;

  challenges.forEach((challenge: string) =>
  {
    const [key, value] = challenge.split("=");

    challengeMap[key.trim()] = window.decodeURI(value.replace(/(^"|"$)/g, ""));
  });

  return challengeMap;
};

/**
 * This method inspects the HTTPS response from a http call
 * for the "WWW-Authenticate header", if present, it grabs
 * the claims challenge from the header and store it in the sessionStorage
 * Should be used when 401 (Unauthorized) response received.
 * For more information, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/claims-challenge#claims-challenge-header-format
 * https://learn.microsoft.com/en-us/entra/identity-platform/claims-challenge?tabs=dotnet
 * @param response
 */
export const extractClaimsFromChallenge =
  (response: HttpResponse<unknown>) =>
  {
    const authenticateHeader: string | null
      = response.headers.get("WWW-Authenticate");

    return authenticateHeader ? parseChallenges(authenticateHeader) : null;
  };

export const storeClaimsFromChallenge =
  (response: HttpResponse<unknown>,
    msalService: MsalService,
    resourceHostname?: string) =>
  {
    const claimsChallengeMap = extractClaimsFromChallenge(response);
    const resHost = resourceHostname ?? (response.url
      ? new URL(response.url).hostname
      : null);

    if (claimsChallengeMap && resHost)
    {
      const msalConfig = msalService.instance.getConfiguration();

      /**
       * This method stores the claim challenge to the session storage
       * in the browser to be used when acquiring a token. To ensure
       * that we are fetching the correct claim from the storage,
       * we are using the clientId of the application and oid
       * (user’s object id) as the key identifier of the claim with schema
       * cc.<clientId>.<oid><resource.hostname>
       */
      addClaimsToStorage(
        claimsChallengeMap["claims"],
        `cc.${msalConfig.auth.clientId}.${msalService.instance.getActiveAccount()?.idTokenClaims?.oid}.${resHost
        }`
      );
    }
  };

/**
 * Transforms Unix timestamp to date and returns a string value of that date
 * @param {number} date Unix timestamp
 * @returns
 */
const changeDateFormat = (date: number) =>
{
  const ms = 1000;
  const dateObj = new Date(date * ms);

  return `${date} - [${dateObj.toString()}]`;
};

type Claim =
{
  claim: string;
  value: string;
  description: string;
};

/**
 * Populates claim, description, and value into an claimsObject
 * @param {String} claim
 * @param {String} value
 * @param {String} description
 * @param {Array} claimsObject
 */
const populateClaim = (
  claim: string,
  value: string,
  description: string,
  // eslint-disable-next-line max-params
  claimsTable: Claim[]): void =>
{
  claimsTable.push({
    claim       : claim,
    value       : value,
    description : description
  });
};

/**
 * Populate claims table with appropriate description
 * @param {Record} claims ID token claims
 * @returns claimsTable
 */
export const createClaimsTable = (claims?: Record<string, unknown>): Claim[] =>
{
  assertIsDefined(claims);
  const claimsTable: Claim[] = [];

  Object.keys(claims).forEach(function (key)
  {
    let desc = "";
    let val: unknown = claims[key];

    switch (key)
    {
      case "aud":
        desc = "Identifies the intended recipient of the token. In ID tokens, the audience is your app's Application ID, assigned to your app in the Azure portal.";
        break;

      case "iss":
        desc = "Identifies the issuer, or authorization server that constructs and returns the token. It also identifies the Azure AD tenant for which the user was authenticated. If the token was issued by the v2.0 endpoint, the URI will end in /v2.0.";
        break;

      case "iat":
        val = changeDateFormat(+(val as string));
        desc = "\"Issued At\" indicates the timestamp (UNIX timestamp) when the authentication for this user occurred.";
        break;

      case "nbf":
        val = changeDateFormat(+(val as string));
        desc = "The nbf (not before) claim dictates the time (as UNIX timestamp) before which the JWT must not be accepted for processing.";
        break;

      case "exp":
        val = changeDateFormat(+(val as string));
        desc = "The exp (expiration time) claim dictates the expiration time (as UNIX timestamp) on or after which the JWT must not be accepted for processing. It's important to note that in certain circumstances, a resource may reject the token before this time. For example, if a change in authentication is required or a token revocation has been detected.";
        break;

      case "name":
        desc = "The name claim provides a human-readable value that identifies the subject of the token. The value isn't guaranteed to be unique, it can be changed, and it's designed to be used only for display purposes. The 'profile' scope is required to receive this claim.";
        break;

      case "preferred_username":
        desc = "The primary username that represents the user. It could be an email address, phone number, or a generic username without a specified format. Its value is mutable and might change over time. Since it is mutable, this value must not be used to make authorization decisions. It can be used for username hints, however, and in human-readable UI as a username. The profile scope is required in order to receive this claim.";
        break;

      case "nonce":
        desc = "The nonce matches the parameter included in the original /authorize request to the IDP.";
        break;

      case "oid":
        desc = "The oid (user object id) is the only claim that should be used to uniquely identify a user in an Azure AD tenant.";
        break;

      case "tid":
        desc = "The id of the tenant where this application resides. You can use this claim to ensure that only users from the current Azure AD tenant can access this app.";
        break;

      case "upn":
        desc = "upn (user principal name) might be unique amongst the active set of users in a tenant but tend to get reassigned to new employees as employees leave the organization and others take their place or might change to reflect a personal change like marriage.";
        break;

      case "email":
        desc = "Email might be unique amongst the active set of users in a tenant but tend to get reassigned to new employees as employees leave the organization and others take their place.";
        break;

      case "acct":
        desc = "Available as an optional claim, it lets you know what the type of user (homed, guest) is. For example, for an individual’s access to their data you might not care for this claim, but you would use this along with tenant id (tid) to control access to say a company-wide dashboard to just employees (homed users) and not contractors (guest users).";
        break;

      case "sid":
        desc = "Session ID, used for per-session user sign-out.";
        break;

      case "sub":
        desc = "The sub claim is a pairwise identifier - it is unique to a particular application ID. If a single user signs into two different apps using two different client IDs, those apps will receive two different values for the subject claim.";
        break;

      case "ver":
        desc = "Version of the token issued by the Microsoft identity platform";
        break;

      case "login_hint":
        desc = "An opaque, reliable login hint claim. This claim is the best value to use for the login_hint OAuth parameter in all flows to get SSO.";
        break;

      case "idtyp":
        desc = "Value is app when the token is an app-only token. This is the most accurate way for an API to determine if a token is an app token or an app+user token";
        break;

      case "uti":
        key = null!;
        break;

      case "rh":
        key = null!;
        break;

      default:
        desc = "";
    }

    if (key)
    {
      populateClaim(key, (val as string), desc, claimsTable);
    }
  });

  return claimsTable;
};