import
{
  Component, Input, OnInit
} from "@angular/core";
import { Store } from "@ngrx/store";

import { CONSTANTS } from "../shared/constants";
import { TRANSLATIONS } from "../shared/translations";
import { messageRemoved } from "../store/app.actions";

import
{
  Message, MessageType
} from "./message";
import { MessageAction } from "./message-action";

@Component({
  selector: "tlv-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"]
})
export class MessageComponent implements OnInit
{
  @Input() message!: Message;

  messageType = MessageType;

  constants = TRANSLATIONS;

  constructor(private readonly store: Store)
  {}

  ngOnInit()
  {
    if (this.message.AutoClose || this.message.Type === this.messageType.success)
    {
      setTimeout(() =>
      {
        this.messageRemove();
      }, CONSTANTS.MessageDelay);
    }
  }


  dispatchAction(actionMessage: MessageAction)
  {
    if (actionMessage.action)
    {
      this.store.dispatch(actionMessage.action);
    }
    this.messageRemove();
  }

  messageRemove()
  {
    this.store.dispatch(messageRemoved({ messageId: this.message.Id }));
  }

  /*
   * TryAgain()
   * {
   *   if (this.message.TryAgainAction)
   *   {
   *     this.store.dispatch(this.message.TryAgainAction);
   *   }
   *   this.messageRemove();
   * }
   */

  /*
   * Approve()
   * {
   *   if (this.message.ApproveAction)
   *   {
   *     this.store.dispatch(this.message.TryAgainAction);
   *   }
   *   this.messageRemove();
   * }
   */

  /*
   * Cancel()
   * {
   *   if (this.message.CancelAction)
   *   {
   *     this.store.dispatch(this.message.CancelAction);
   *   }
   *   this.messageRemove();
   * }
   */

  /*
   * CancelSuccessfully()
   * {
   *   this.messageRemove();
   * }
   */
}