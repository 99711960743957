import { Injectable } from "@angular/core";

import
{
  Observable, catchError
} from "rxjs";

import
{
  APIService, unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator, ResponseErrorHandler
} from "./response-error-handler.service";

import { Communication } from "../models/communication";

@Injectable({
  providedIn: "root"
})
export class CommunicationService
{
  private static readonly url = "Communication";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("CommunicationService");
  }

  getCommunicationsByPersonId(personId: string): Observable<Communication[]>
  {
    return this.api.get<Communication[]>(`${CommunicationService.url}/${personId}`).pipe(
      unwrapResponse(),
      catchError(this.handleError("getCommunicationsByPersonId", new Array<Communication>()))
    );
  }
}