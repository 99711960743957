import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Optional, Inject, NgModule } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { of, merge, fromEvent, EMPTY } from 'rxjs';
import { mapTo, startWith, catchError } from 'rxjs/operators';
import * as i1 from '@angular/router';
const OFFLINE_ROUTE_OFFLINE = new InjectionToken('offline-config-route-offline', {
  providedIn: 'root',
  factory: () => '/offline'
});
const OFFLINE_ROUTE_UNAVAILABLE = new InjectionToken('offline-config-route-unavailable', {
  providedIn: 'root',
  factory: () => '/unavailable'
});
const OFFLINE_GUARDS_REDIRECT = new InjectionToken('offline-config-guards-redirect', {
  providedIn: 'root',
  factory: () => true
});
/**
 * @deprecated Use `OfflineModule.forRoot()` instead
 */
function offlineProviders(config) {
  return [config.routeOffline ? {
    provide: OFFLINE_ROUTE_OFFLINE,
    useValue: config.routeOffline
  } : [], config.routeUnavailable ? {
    provide: OFFLINE_ROUTE_UNAVAILABLE,
    useValue: config.routeUnavailable
  } : [], config.guardsRedirect === false ? {
    provide: OFFLINE_GUARDS_REDIRECT,
    useValue: false
  } : []];
}
class Network {
  constructor(router, platformId, routeOffline, routeUnavailable) {
    this.router = router;
    this.platformId = platformId;
    this.routeOffline = routeOffline;
    this.routeUnavailable = routeUnavailable;
    /* Store instance in a static property to allow access to injected services in the RxJS static operator
     * Should be done only once */
    if (!Network.instance) {
      Network.instance = this;
    }
    this.onlineChanges = !isPlatformBrowser(this.platformId) ? of(true) : merge(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    fromEvent(window, 'online').pipe(mapTo(true)),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    fromEvent(window, 'offline').pipe(mapTo(false))).pipe(startWith(this.online));
  }
  /** Check if Internet connection is available */
  get online() {
    return isPlatformBrowser(this.platformId) ? navigator.onLine : true;
  }
  /**
   * Catch offline errors (no Internet connection) and server errors (HTTP status 5xx)
   * and redirect to `/offline` or `/unavailable` page (routes can be changed via `OfflineModule.forRoot()`)
   */
  static catchOffline() {
    return catchError(Network.catchCallback);
  }
  static catchCallback(error) {
    if (!Network.instance) {
      console.log(`You need to inject the Network service at least once, for example in your AppComponent constructor.`);
      throw error;
    } else if (!Network.instance.router) {
      console.log(`You need to import RouterModule.forRoot([]) in your application.`);
      throw error;
    } else {
      if (!Network.instance.online) {
        Network.instance.router.navigate([Network.instance.routeOffline]).catch(() => {
          // Nothing to do
        });
        return EMPTY;
      } else if (error instanceof HttpErrorResponse && error.status >= 500 && error.status < 600) {
        Network.instance.router.navigate([Network.instance.routeUnavailable]).catch(() => {
          // Nothing to do
        });
        return EMPTY;
      } else {
        throw error;
      }
    }
  }
}
Network.instance = null;
Network.ɵfac = function Network_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || Network)(i0.ɵɵinject(i1.Router, 8), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(OFFLINE_ROUTE_OFFLINE), i0.ɵɵinject(OFFLINE_ROUTE_UNAVAILABLE));
};
Network.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Network,
  factory: Network.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Network, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Router,
      decorators: [{
        type: Optional
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [OFFLINE_ROUTE_OFFLINE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [OFFLINE_ROUTE_UNAVAILABLE]
      }]
    }];
  }, null);
})();
/**
 * Catch offline errors (no Internet connection) and server errors (HTTP status 5xx)
 * and redirect to `/offline` or `/unavailable` page (routes can be changed via `OfflineModule.forRoot()`)
 */
const catchOffline = Network.catchOffline;

/**
 * Check if Internet connection is available to allow a navigation to a route or not.
 * By default, if Internet connection is not available, the user will be redirected to the /offline page.
 * This behavior and the URL of redirection can be configured via `OfflineModule.forRoot()`.
 */
class OnlineGuard {
  constructor(router, network, routeOffline, guardsRedirect) {
    this.router = router;
    this.network = network;
    this.routeOffline = routeOffline;
    this.guardsRedirect = guardsRedirect;
  }
  canActivate() {
    return this.guard();
  }
  canActivateChild() {
    return this.guard();
  }
  canLoad() {
    return this.guard();
  }
  guard() {
    if (!this.network.online) {
      if (this.guardsRedirect) {
        this.router.navigate([this.routeOffline]).catch(() => {
          // Nothing to do
        });
      }
      return false;
    }
    return true;
  }
}
OnlineGuard.ɵfac = function OnlineGuard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OnlineGuard)(i0.ɵɵinject(i1.Router), i0.ɵɵinject(Network), i0.ɵɵinject(OFFLINE_ROUTE_OFFLINE), i0.ɵɵinject(OFFLINE_GUARDS_REDIRECT));
};
OnlineGuard.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: OnlineGuard,
  factory: OnlineGuard.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OnlineGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.Router
    }, {
      type: Network
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [OFFLINE_ROUTE_OFFLINE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [OFFLINE_GUARDS_REDIRECT]
      }]
    }];
  }, null);
})();

/**
 * This module does not contain anything, it's only useful to provide options via `.forRoot()`.
 */
class OfflineModule {
  /**
   * Only useful to provide options, otherwise it does nothing.
   * **Must be used at initialization, ie. in `AppModule`, and must not be loaded again in another module.**
   *
   * @example
   * NgModule({
   *   imports: [OfflineModule.forRoot({
   *     routeOffline: '/custom/offline',
   *     routeUnavailable: '/custom/unavailable',
   *   })]
   * })
   * export class AppModule
   */
  static forRoot(config) {
    return {
      ngModule: OfflineModule,
      providers: [config.routeOffline ? {
        provide: OFFLINE_ROUTE_OFFLINE,
        useValue: config.routeOffline
      } : [], config.routeUnavailable ? {
        provide: OFFLINE_ROUTE_UNAVAILABLE,
        useValue: config.routeUnavailable
      } : [], config.guardsRedirect === false ? {
        provide: OFFLINE_GUARDS_REDIRECT,
        useValue: false
      } : []]
    };
  }
}
OfflineModule.ɵfac = function OfflineModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OfflineModule)();
};
OfflineModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OfflineModule
});
OfflineModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OfflineModule, [{
    type: NgModule
  }], null, null);
})();

/*
 * Public API Surface of offline
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Network, OFFLINE_GUARDS_REDIRECT, OFFLINE_ROUTE_OFFLINE, OFFLINE_ROUTE_UNAVAILABLE, OfflineModule, OnlineGuard, catchOffline, offlineProviders };