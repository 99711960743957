import { Component } from "@angular/core";
import { TRANSLATIONS } from "../../shared/translations";

@Component({
  selector    : "tlv-footer",
  templateUrl : "./footer.component.html",
  styleUrls   : ["./footer.component.scss"]
})
export class FooterComponent
{
  constants = TRANSLATIONS;
}